html ::-webkit-scrollbar {
  width: 0;
}
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  background: #fff;
  font-family: ProximaNova;
  font-style: normal;
  font-weight: normal;
  color: #fff;
  font-size: 18px;
  line-height: 27px;
}
.container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 1180px;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
button {
  background-color: transparent;
  cursor: pointer;
  border: none;
  padding: 0;
}
button:focus {
  outline: none;
}
a {
  text-decoration: none;
  cursor: pointer;
  outline: none;
}
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}
@keyframes jumpMouse {
  0% {
    top: 0;
  }
  70% {
    top: 5px;
  }
  100% {
    top: 12px;
  }
}
.header {
  display: flex;
  align-items: center;
}
.header-menu {
  display: flex;
}
.header-menu__toggle {
  width: 22px;
  padding: 8px 0;
  display: none;
  font-size: 0;
  color: transparent;
  border: 0;
  background: none;
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 24px;
}
.header-menu__toggle:focus {
  outline: 0;
}
.header-menu__toggle__item {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #fff;
  position: absolute;
}
.header-menu__toggle.active .header-menu__toggle__item {
  background: none;
}
.header-menu__toggle__item:before,
.header-menu__toggle__item:after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #fff;
  position: absolute;
  left: 0;
  transition: all 0.3s linear;
}
.header-menu__toggle__item:before {
  top: -6px;
}
.header-menu__toggle__item:after {
  bottom: -6px;
}
.header-menu__toggle.active .header-menu__toggle__item:before {
  transform: rotate(45deg) translateX(11px) translateY(-2px);
  width: 23px;
}
.header-menu__toggle.active .header-menu__toggle__item:after {
  transform: rotate(-45deg) translateX(11px) translateY(2px);
  width: 23px;
}
/* для элемента input c type="checkbox" */
.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
/* для элемента label, связанного с .custom-checkbox */
.custom-checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
/* создание в label псевдоэлемента before со следующими стилями */
.custom-checkbox + label::before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 75%;
}
/* стили при наведении курсора на checkbox */
.custom-checkbox:not(:disabled):not(:checked) + label:hover::before {
  border-color: #fff;
}
/* стили для активного чекбокса (при нажатии на него) */
/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
.custom-checkbox:focus:not(:checked) + label::before {
  border-color: #adb5bd;
}
/* стили для чекбокса, находящегося в состоянии checked */
.custom-checkbox:checked + label::before {
  border-color: #fff;
  background-color: transparent;
  background-image: url("../img/icon/cube.svg");
}
.after-horizontal {
  position: relative;
}
.after-horizontal::after {
  height: 1.1px;
  background-color: transparent;
  content: "";
  width: 0;
  right: -6px;
  position: absolute;
  bottom: -8px;
  transition: 0.3s;
}
.after-horizontal:hover::after {
  background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  width: 90%;
  transition: 0.3s;
}
.after-horizontal::before {
  height: 1.1px;
  background-color: transparent;
  content: "";
  width: 0;
  left: -6px;
  position: absolute;
  top: -8px;
  transition: 0.3s;
}
.after-horizontal:hover::before {
  background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  width: 90%;
  transition: 0.3s;
}
.after-vertical {
  position: relative;
}
.after-vertical::after {
  width: 1.2px;
  height: 0;
  background-color: transparent;
  content: "";
  right: -7px;
  position: absolute;
  bottom: -8px;
  transition: 0.3s;
}
.after-vertical:hover::after {
  background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  height: 100%;
  transition: 0.3s;
}
.after-vertical::before {
  width: 1.2px;
  height: 0;
  background-color: transparent;
  content: "";
  left: -6px;
  position: absolute;
  top: -8px;
  transition: 0.3s;
}
.after-vertical:hover::before {
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  height: 100%;
  transition: 0.3s;
}
.map {
  -webkit-filter: grayscale(90%);
  filter: invert(90%) grayscale(90%);
  width: 100vw;
  height: 600px;
  z-index: 2;
}
@media screen and (max-width: 1180px) {
  .header {
    height: 55px;
  }
  .header-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100vw;
    height: 100vh;
    position: fixed;
    padding: 60px 32px 16px 32px;
    background-color: #0f0f0f;
    transition: transform 0.5s;
    top: 0;
    transform: translateY(-100%);
  }
  .header-menu__toggle {
    display: block;
    z-index: 15;
  }
  .header-menu.active {
    transform: translateY(0);
    transition: transform 0.5s;
    padding: 60px 32px 16px 32px;
    border-bottom: 1px solid #0f0f0f;
  }
}
@media screen and (max-width: 768px) {
  .header-menu__toggle {
    top: 18px;
  }
  .item:focus .conture {
    border: 1px solid #fff;
  }
  .item:focus .gradient {
    background: none;
  }
  .gradient:hover {
    background: none;
  }
  .custom-checkbox + label::before {
    background-size: auto;
  }
}
@media screen and (max-width: 600px) {
  .header-menu {
    padding: 60px 16px 32px 16px;
  }
  .header-menu.active {
    padding: 60px 16px 32px 16px;
  }
  .after-horizontal {
    position: relative;
  }
  .after-horizontal::after {
    right: -4px;
    bottom: -6px;
  }
  .after-horizontal::before {
    left: -4px;
    top: -6px;
  }
  .after-vertical {
    position: relative;
  }
  .after-vertical::after {
    right: -5px;
    bottom: -6px;
  }
  .after-vertical::before {
    left: -5px;
    top: -6px;
  }
}
